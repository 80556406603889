/** Cведения о приемке */
export const AcceptanceStatuses = {
    Undefined: 'Undefined',                       // Неизвестный статус
    Participant: 'Participant',                   // Участники
    CheckPersonalData: 'CheckPersonalData',       // Проверка персональных данных
    ChooseDate: 'ChooseDate',                     // Выбор даты
    NeedPrepareDocuments: 'NeedPrepareDocuments', // Встреча на объекте
    Meeting: 'Meeting',                           // Ожидание встречи
    SuccessResult: 'SuccessResult',               // Результат без замечаний
    ResultWithRemarks: 'ResultWithRemarks',       // С замечаниями
    FailResult: 'FailResult',                     // Результат без подписи о приемке
    Cancelled: 'Cancelled',                       // Отменена
} as const;

/** Типы объекта */ 
export const RealtyTypes = {
    Undefined: 0,      // Не определен
    Apartment: 1,      // Квартира
    ParkingSpace: 2,   // Машиноместо
    Storage: 3,        // Кладовая
    NonResidential: 4, // Нежилое
} as const;